<template>
  <div class="listPicTwo">
    <div class="image">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="layout">
      <div class="contentlistPicTow">
        <div class="left">
          <div class="listSlid">
            <div class="listSlidHd" @click="menuShow">
              <span>{{slideHdTitle}}</span>
              <i class="iconfont" v-if="!isMenuShow" style="color: #fff;">&#xe65f; </i>
              <i class="iconfont" v-if="isMenuShow" style="color: #fff;">&#xe65e; </i>
            </div>
            <div class="listSlidList" v-if="isMenuShow">
              <div class="listSlidListInner" :class="{on : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index">
                <a 
                  v-if="item.url && item.url !== ''" 
                  target="_blank" 
                  :href="item.url">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <a 
                  v-if="(!item.url || item.url == '') && item.entTChannelUuid != '01830005' && item.entTChannelUuid != '01830001' && item.entTChannelUuid != '01860001' && item.entTChannelUuid != '01860004'&&  item.entTChannelUuid != '01800005'"  
                  :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <a 
                  style="display: none"
                  v-if="item.entTChannelUuid == '01800005'" :href="`/list/01800005`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>

                <a 
                  v-if="item.entTChannelUuid == '01830005'" :href="`/listPicTwo/01830005`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <a 
                  v-if="item.entTChannelUuid == '01830001'" :href="`/listVideo/01830001`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <a 
                  v-if="item.entTChannelUuid == '01860001'" :href="`/listVideo/01860001`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <a 
                  v-if="item.entTChannelUuid == '01860004'" :href="`/listPicTwo/01860004`">
                  <div style="margin-left: 40px;">
                    <span>{{item.name}}</span>
                    <img src="../assets/image/on2.png" class="listImg">
                    <img src="../assets/image/on1.png" class="listImg2">
                  </div>
                </a>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="listMainRight fr">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdGrop">
                {{$t('m.listPageOption')}}：<a href="/" style="font-weight: 700; color: #303133;">{{$t('m.listPageHome')}}</a>
                <span v-if="viewSlideHdParent"> > <a :href="slideHdParentUrl">{{slideHdParentName}}</a> ></span> 
                <span style="color: #0162b3;">&nbsp;{{slideHdName}}</span>
              </div>
            </div>
            <div class="listContainer listPicBox">
              <el-row>
                <el-col :span="8" v-for="(item, index) in listData" :key="index">
                  <a class="listPicInner" 
                  :href="item.url && item.url !== '' ? item.url : `/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`"
                   @mouseenter="mouseenterPic(index)"  @mouseleave="mouseleavePic()">
                    <!-- <div class="w-simImg">
                      <div class="listPicImg">
                        <img :src="item.thumb1" alt="">
                      </div>
                      <h3 class="listPicTitle" :class="{active : active  == index}">{{item.title}} >></h3>
                      <div :class="{active : active  == index}" class="artDiv wow animate__animated animate__bounceInUp" data-wow-delay="0.5s"  data-wow-iteration= "1"  data-wow-duration="0.5s" >
                        <div class="artDesc">{{artDesc  | ellipsis}}</div>
                      </div>
                    </div>
                    <div class="listPicBg" v-show="active == index">
                    </div> -->
                    <div class="w-simImg">
                      <div class="listPicImg">
                        <img :src="item.thumb1" alt="" />
                      </div>
                      <div class="item_text">
                        <div class="text_omit">{{ item.title }}</div>
                        <div class="text_time">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
                      </div>
                    </div>
                  </a>
                </el-col>
              </el-row>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange"
                  >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildChannel, getChannelInfoNew, articles , } from '@/api/common'

export default {
  name: 'list',
  data(){
    return {
      isMenuShow:true,
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      indexImg: '',
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 6,
      pageNum: 1,
      total: 0,
      listData: [],
      listDataEn: [],
      listId: '',
      isthird: false,
      slideHdName2: '',
      isClickid2: '',
      artDesc:'',
      applicationUuid:'',
      lang: 'zh-CN',
    }
  },
  filters: {
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 68) {
        return value.slice(0, 68) + '...'
      }
      return value
    },
  },
  mounted(){
    var _this = this
    new this.$wow.WOW().init({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true,
      live: true
    }) 

    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id

    // let lang = getCookie('lang')
    let lang = localStorage.getItem('lang');
    if( lang == 'en-US'){
      this.applicationUuid = this.webIdEn
    }
    else {
      this.applicationUuid = this.webId
    }

    _this.getChildChannel()
    _this.getChannelInfoNew()
    _this.articles()
  },
  methods: {
    mouseenterPic(index) {
      var _this = this

      _this.active = index
    },
    mouseleavePic() {
      var _this = this
      _this.active = -1
    },
    menuShow(){
      this.isMenuShow = !this.isMenuShow
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: this.applicationUuid,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const {
          code,
          data
        } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfoNew() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id,
        applicationUuid: this.applicationUuid,
      }
      getChannelInfoNew(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == "0") {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            if(data.fileName && data.fileName !== ''){
              _this.indexImg = data.fileName
            }
            else{
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid,
            applicationUuid: this.applicationUuid,
          }
          getChannelInfoNew(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
              if(data.fileName && data.fileName !== ''){
                _this.indexImg = data.fileName
              }
              else{
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
            }
          })
        }
      })
    },
    // 获取列表
    articles() {
      var _this = this
      
      let data = {
        entTApplicationUuid: this.applicationUuid,
        channelUuid: _this.$route.params.id,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        }
      })
    },
    currentChange(e){
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style lang="css" scoped>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listPicTwo .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.contentlistPicTow{
  min-width: 1200px;
  min-height: 300px;
  display: flex;
  margin: 20px 0 30px;
}
.left{
  width: 240px;
}
.listMainRight{
  width: 940px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: .6;
}
.listPicTwo .listPicInner{
  display: block;
  width: 286px;
  height: 260px;
  position: relative;
  margin: 0 30px 30px 0;
}
.listPicBg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  border-radius: 8px;
  background-color: rgba(0, 107, 204, 0.5);
  z-index: 10;
}

.listPicTwo .w-simImg{
  width: 286px;
  height: 260px;
  box-sizing: border-box;
}
.listPicTwo .listPicImg {
 width: 286px;
  height: 182px;
}
.listPicTwo .listPicImg img{
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
}
.listPicTwo .listPicTitle{
  padding: 0 6px;
  width: 148px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  margin-top: -30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #000;
  opacity: .5;
  -webkit-line-clamp: 1;
  border-radius: 0 0 8px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  z-index: 20;
}
.listPicTwo .listPicTitle.active{
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transform:translateY(-50px);
  opacity: 1;
  z-index: 20;
}

.listPicTwo .artDesc{
  margin: 0;
  padding: .5em 5px 0;
  opacity: .85;
  line-height: 15px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font: 12px/1 "微软雅黑","宋体";
  z-index: 22;
  color: #fff;
}

.listPicTwo .artDiv{
  width: 160px;
  height: 50px;
  z-index: 22;
  display: none;
  margin-top: -50px;
}
.listPicTwo .artDiv.active {
  display: block;
  transition: all 3s ease-in;
  transform:translateY(-50px);
  z-index: 22;
}
.w-simImg .item_text {
  box-sizing: border-box;
  color: #444;
  font-family: Microsoft YaHei;
  font-size: 12px;
  font-weight: 600;
  height: 30%;
  line-height: 20px;
  padding: 10px;
  width: 100%;
  background: #f9f9f9;
}

.w-simImg .text_omit {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  min-height: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-all;
}

.w-simImg .text_time {
  font-weight: 400;
  margin-top: 15px;
}
.listPicInner:hover {
  box-shadow: 0 3px 29px 0 hsla(0, 3%, 78%, 0.418);
}
.listPicInner:hover .w-simImg .item_text {
  background-color: #f2f6f9;
}

.listPicInner:hover .w-simImg .text_omit {
  color: #006bcc;
}

</style>